/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

import { Form } from '@unform/web';

import casaImg from '../../assets/casa.svg';
import {
  Divisionbra,
  Container,
  Searchbar,
  Houses,
  AddTable,
  Endbar,
} from './styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Select from '../../components/Select';
import { FIND_ADVERTISEMENTS } from '../../graphql/resolvers/advertisement';
import { IAdvertisement } from '../../graphql/entities/advertisement';

interface IQueryData {
  advertisements: {
    list: IAdvertisement[];
    total: number;
  };
}

const Home: React.FC = () => {
  const [page, setPage] = useState(1);
  const [per_page] = useState(8);

  const { data, loading, error } = useQuery<IQueryData>(FIND_ADVERTISEMENTS, {
    variables: {
      per_page,
      page,
    },
  });

  if (loading) {
    return <h1>Carregando anúncios...</h1>;
  }

  if (error) {
    return (
      <div>
        <h1>Ocorreu um erro ao carregar lista de anúncios</h1>
        <p>{error.message}</p>
      </div>
    );
  }

  return (
    <Container>
      <Carousel showThumbs={false} infiniteLoop autoPlay>
        <div>
          <img src={casaImg} alt="house" />
          <p>Casa da barra</p>
        </div>
        <div>
          <img src={casaImg} alt="house" />
          <p>Casa da lagoa</p>
        </div>
        <div>
          <img src={casaImg} alt="house" />
          <p>Casa Paris</p>
        </div>
      </Carousel>
      <Searchbar />

      <Divisionbra>
        <Link to="/">Rio de Janeiro</Link>
        <Link to="/">São paulo</Link>
        <Link to="/">Belo Horizonte</Link>
        <Link to="/">Miami</Link>
      </Divisionbra>
      <Form onSubmit={() => {}}>
        <Select
          name="type"
          options={[
            { value: 'house', label: 'Casa' },
            { value: 'hotel', label: 'Hotel' },
            { value: 'farm', label: 'Fazenda' },
          ]}
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: 'white',
              marginTop: 20,
              width: 300,
            }),
          }}
        />
      </Form>
      <Houses>
        <AddTable>
          {data &&
            data.advertisements.list.map((advertisement) => (
              <li>
                <Link to={`advertisement-details/${advertisement.id}`}>
                  <img src={casaImg} alt="house" />
                  <h6>{advertisement.title}</h6>
                  <hr />
                  <p>Venda</p>
                  <h6>Rio de janeiro</h6>
                  <p>{advertisement.property.value}</p>
                  <h6>Preço</h6>
                  <hr />
                </Link>
              </li>
            ))}
        </AddTable>
      </Houses>
      <Endbar>
        <button
          disabled={page === 1}
          type="button"
          onClick={() => setPage((status) => status - 1)}
        >
          <BiArrowToLeft size={30} />
        </button>
        {/* <div>{data && <p>{data.advertisements.total}</p>}</div> */}
        <button
          disabled={!data || page >= data.advertisements.total / per_page}
          type="button"
          onClick={() => setPage((status) => status + 1)}
        >
          <BiArrowToRight size={30} />
        </button>
      </Endbar>
    </Container>
  );
};

export default Home;

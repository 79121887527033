import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Profile from '../pages/Profile';
import SignUp from '../pages/SignUp';
import Faq from '../pages/Faq';
import Home from '../pages/Home';
import Terms from '../pages/Terms';
import Contacts from '../pages/Contacts';
import AdvertisementDetails from '../pages/AdvertisementDetails';
import AdvertisementRegistration from '../pages/AdvertisementRegistration';
import AdvertisementSearch from '../pages/AdvertisementSearch';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/faq" exact component={Faq} />

    <Route path="/signup" exact component={SignUp} />
    <Route path="/advertisement-search" exact component={AdvertisementSearch} />

    <Route path="/terms" exact component={Terms} />
    <Route path="/contacts" exact component={Contacts} />

    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/profile" component={Profile} isPrivate />
    <Route
      path="/advertisement-registration"
      component={AdvertisementRegistration}
      isPrivate
    />
    <Route path="/advertisement-details/:id" component={AdvertisementDetails} />
  </Switch>
);

export default Routes;

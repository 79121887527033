import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FiHome } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { FaBed, FaParking, FaShower } from 'react-icons/fa';
import { IoIosCube } from 'react-icons/io';
import {
  Container,
  Adescription,
  Adinfo,
  Adseller,
  Adlist,
  Carrosel,
} from './styles';

import casaImg from '../../assets/casa.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IAdvertisement } from '../../graphql/entities/advertisement';
import { FIND_ADVERTISEMENT_BY_ID } from '../../graphql/resolvers/advertisement';

interface IQueryData {
  getAdvertisementById: IAdvertisement;
}

const AdvertisementDetails: React.FC = () => {
  const [advertisement, setAdvertisement] = useState<
    IAdvertisement | undefined
  >();
  const { id } = useParams<{ id: string }>();
  
  const { data , error} = useQuery<IQueryData>(FIND_ADVERTISEMENT_BY_ID, {
    variables: { id: parseInt(id ,10) },
  });

  useEffect(() => {
    setAdvertisement(data?.getAdvertisementById);
    console.log(error);
  }, [data, error]);

  return (
    <Container>
      <Carrosel>
        <Carousel
          showThumbs
          dynamicHeight={false}
          width="100%"
          thumbWidth={200}
        >
          {advertisement &&
            advertisement.gallery.map((item) => (
              <img alt="image3" src={item.url} />
            ))}
        </Carousel>
      </Carrosel>
      <Adescription>
        <p>
          Descrição:
          <br />
          {advertisement && advertisement.description}
        </p>

        <p>
          Endereço:
          <br />
          {advertisement &&
            `${advertisement.property.address.country} ${advertisement.property.address.state} ${advertisement.property.address.neighborhood} ${advertisement.property.address.number}`}
        </p>
      </Adescription>
      <Adinfo>
        {/* {advertisements.map(advertisement =>
            <li>
            <h6>{advertisement.title}</h6>
            <img src={advertisement.gallery[0].filename} alt="house" />
            <p>{advertisement.property.value}</p>

          </li>
            )} */}
        <FaBed size="2rem" color="#c1b994" />
        <p>infos</p>
        <FaParking size="2rem" color="#c1b994" />
        <p>infos</p>
        <FaShower size="2rem" color="#c1b994" />
        <p>infos</p>
        <IoIosCube size="2rem" color="#c1b994" />
        <p>infos</p>
      </Adinfo>
      <Adseller>
        <FiHome size="2rem" color="#c1b994" />
        <p>infos</p>
      </Adseller>
      <Adlist>
        <li>
          <h6>Casa na Barra</h6>
          <img src={casaImg} alt="house" />
          <p>R$: 16.000.000,00</p>
          <h6>1500m²</h6>
        </li>
        <li>
          <h6>Casa na Barra</h6>
          <img src={casaImg} alt="house" />
          <p>R$: 16.000.000,00</p>
          <h6>1500m²</h6>
        </li>
      </Adlist>
    </Container>
  );
};

export default AdvertisementDetails;

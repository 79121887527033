export type AdvertisementType = 'PURCHASE' | 'TENANCY';

export const advertisementTypeDescription = {
  '': 'Todos',
  PURCHASE: 'Compra',
  TENANCY: 'Aluguel',
} as const;

export type PropertyType =
  | 'HOME'
  | 'APARTMENT'
  | 'PENTHOUSE'
  | 'GRANGE'
  | 'FARM'
  | 'TERRAIN'
  | 'SHED'
  | 'CORPORATE'
  | 'OFFICE'
  | 'STORE'
  | 'HOTEL'
  | 'INN'
  | 'ISLAND'
  | 'CUSTOMIZED';

export const propertyTypeDescription = {
  '': 'Todos',
  HOME: 'Casa',
  APARTMENT: 'Apartamento',
  PENTHOUSE: 'Cobertura',
  GRANGE: 'Sítio',
  FARM: 'Fazenda',
  TERRAIN: 'Terreno',
  SHED: 'Galpão',
  CORPORATE: 'Corporativo',
  OFFICE: 'Escritório',
  STORE: 'Loja',
  HOTEL: 'Hotel',
  INN: 'Pousada',
  ISLAND: 'Ilha',
  CUSTOMIZED: 'Customizado',
} as const;

interface Media {
  type: string;
  filename: string;
  url: string;
}

export interface IAdvertisement {
  id: string;
  title: string;
  gallery: Media[];
  property: {
    value: number;
    address: {
      country: string;
      state: string;
      neighborhood: string;
      street: string;
      number: string;
    };
  };
  description: string;
}

//    POOLS: 'piscinas',
//    HELIPORT: 'heliporto',
//    STEAM ROOM: 'sauna',
//    GUEST HOUSE:'casa de hospedes',
//    PORT:'porto',
//    SECURITY:'segurança',
//    RIVER: 'rio',
//    STABLE: 'estabulo',
//    SQUARE METERS: 'metros quadrados',
//    MEETING ROOMS:  'salas de reunião',

//   export const advertisementExtraDescriptionBedrooms = {
//     '': 'Todos',
//    BEDROOMS: 'quartos' ,
//  } as const;

//   export const advertisementExtraDescriptionBathrooms = {
//     '': 'Todos',
//   //    BATHROMMS: 'banheiros',
//  } as const;

//   export const advertisementExtraDescriptionParkingspot = {
//     '': 'Todos',
//   //    PARKINGSPOT: 'vagas',
//  } as const;

import gql from 'graphql-tag';

export const CREATE_ADVERTISEMENT = gql`
  mutation createAdvertisement(
    $title: String!
    $description: String
    $status: Boolean!
    $type: AdvertisementTypeEnum!
    $address_visible: Boolean!
    $type_property: PropertyTypeEnum!
    $value: Float!
    $country: String!
    $state: String!
    $postal_code: String!
    $neighborhood: String!
    $number: String
    $complement: String
    $street: String!
  ) {
    createAdvertisement(
      data: {
        title: $title
        description: $description
        status: $status
        type: $type
        address_visible: $address_visible
        property: {
          type: $type_property
          value: $value
          country: $country
          state: $state
          postal_code: $postal_code
          neighborhood: $neighborhood
          number: $number
          complement: $complement
          street: $street
        }
      }
    ) {
      id
      title
    }
  }
`;

export const FIND_ADVERTISEMENT_BY_ID = gql`
  query getAdvertisementById($id: Int!) {
    getAdvertisementById(id: $id) {
      id
      title
      description
      type
      user {
        id
        name
        creci
        email
        phone
        type
      }
      gallery {
        url
      }
      property {
        address {
          country
          state
          postal_code
          neighborhood
          sub_neighborhood
          street
          number
          complement
        }
        type
        value
      }
    }
  }
`;

export const FIND_ADVERTISEMENTS = gql`
  query findAdvertisements($per_page: Int, $page: Int) {
    advertisements(data: { per_page: $per_page, page: $page }) {
      list {
        id
        title
        type
        property {
          type
        }
        gallery {
          url
        }
        user {
          name
        }
      }
      total
    }
  }
`;

export const FIND_USERS_ADVERTISEMENTS = gql`
  query findAdvertisements($per_page: Int, $page: Int, $user_id: String) {
    advertisements(data: { per_page: $per_page, page: $page, filter:{user_id: $user_id}}) {
      list {
        id
        title
        type
        property {
          type
        }
        gallery {
          url
        }
        user {
          name
        }
      }
      total
    }
  }
`;

import React from 'react';
import { Container,  Houses } from './styles';
import {} from '../../components/Header/styles';
import casaImg from '../../assets/alert.svg';
// import { FiSearch } from 'react-icons/fi';
// import Input from '../../components/Input';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Faq: React.FC = () => {
  return (
    <Container>
      <Houses>
        <div>
        <img src={casaImg} alt="house" />
        <p>Ainda em construção</p>
        </div>
        </Houses>
    </Container>
  );
};

export default Faq;

import React from 'react';
import { Link } from 'react-router-dom';
import logoimg2 from '../../assets/logo2.png';

import { Container, Line, Base, Footerform } from './styles';

const Footer: React.FC = () => {
  return (
    <>
      <Container>

        <Line />
        <Footerform>
          <a href="/">Navegação</a>
          <a href="https://www.facebook.com/imoveisdeluxo.com.br">Facebook</a>
          <a href="/">Informações</a>
          <Link to="/terms">Termos de uso</Link>
        </Footerform>
        <Line />
        <Base>
          <img src={logoimg2} alt="imoveis de luxo" />
          <p>&copy; 2007 - Imóveis de Luxo - Todos os direitos reservados</p>
        </Base>
      </Container>
    </>
  );
};

export default Footer;

import React from 'react';
import { Container, TermsContainer } from './styles';

const Terms: React.FC = () => {
  return (
    <Container>
      <h1>Termos de Uso - Imóveis de Luxo</h1>
      <TermsContainer>
      <p>
O imoveisdeluxo.com.br atua como mero veiculador de informações,
serviços ou outros materiais advindos de diversos anunciantes, e,
dessa forma, o imoveisdeluxo.com.br não assume responsabilidade por
nenhuma consequência que ocorra por qualquer relação direta ou
indireta, ação ou omissão que o usuário faça baseado nas informações,
serviços ou outros materiais deste site.
</p>
<p>
O imoveisdeluxo.com.br procura manter o site sempre atualizado,
preciso e completo, contudo, não se responsabiliza por perda ou dano
relacionado à precisão das informações aqui contidas. O
imoveisdeluxo.com.br entende indispensável e imprescindível que o
usuário verifique toda a documentação de qualquer dos produtos
ofertados, antes de concluir qualquer negócio.
</p>
<ul>
<li>USO do SITE</li>
<p>
O anunciante do site poderá veicular, acessar, modificar, imprimir e
divulgar suas páginas e anúncios através de prévio contato e
aprovação do material pelo imoveisdeluxo.com.br.
</p>
<p>
Os usuários poderão acessar, “baixar” e imprimir materiais deste
site para seu uso pessoal e não-comercial. Os usuários não poderão
modificar, copiar, distribuir, transmitir, exibir, reproduzir,
publicar, licenciar, criar trabalho derivado, colocar e/ou usar em
outra página da internet.
</p>
<p>
Ainda como condição de uso deste site, os anunciantes e usuários
garantem ao imoveisdeluxo.com.br que não usarão este site para
nenhum propósito ilegal ou proibido por estes termos. Quando da
violação destes, a permissão de uso deste site, será automaticamente
encerrada, com base nos artigos 18 e 20 caput e parágrafo único da Lei  12.965/2014 e nos termos desta.
</p>
<p>
No Rio de Janeiro, o site mantêm uma equipe de corretagem, por isso,
os anúncios ficarão sujeitos à verificação de possível concorrência
antes de serem publicados. Nos colocamos a disposição para fazer
parcerias com corretores que queiram colocar seus produtos do Rio de
Janeiro em parceria com os corretores do site.
</p>
<li>DIREITOS AUTORAIS e MARCAS</li>
<p>
A apresentação, o layout e o material deste site são protegidos pela
legislação de “Direito Autoral”. A reprodução, distribuição e
transmissão dos direitos autorais dos materiais deste site não são
permitidas sem expresso consentimento por escrito.
</p>
<p>
As marcas, logotipos, produtos, denominações de serviços e outros
materiais disponibilizados no site são de propriedade
imoveisdeluxo.com.br e seus associados, sendo proibida sua
utilização por terceiros. Todas as demais marcas, logotipos e
produtos porventura utilizados no site pertencem aos seus
respectivos proprietários, que autorizaram o imoveisdeluxo.com.br a
utilizar as mesmas no site.
</p>
<li>COBRANÇA DO SERVIÇO</li>
<p>
Consentimento do usuário (nos termos dos artigos 7°, I, V; 8° da Lei Geral de Proteção de Dados - Lei 13.709/2018): 
O simples cadastro de anunciante não gera custo. O anunciante poderá
publicar 1 anúncio gratuitamente.
</p>
<p>
Para anunciar mais imóveis, oferecemos planos anuais de até 10, 20,
30, 40, 50 e 100 anúncios. Cada um com seu respectivo valor anual,
que é informado no cadastro do anunciante.
</p>
<p>
O pagamento das anuidades são feitos através de depósito bancário no banco
Itaú. O depósito dever ser feito após a realização do cadastro de
anunciante, com o valor correspondente do respectivo plano de
anúncios escolhido. O anunciante terá até 5 dias úteis após o
cadastro para realizar o depósito de pagamento. Caso não seja
realizado o depósito , seu cadastro continuará válido, porém, apenas
para a utilização do serviço gratuito com 1 anúncio.
</p>
<li>LINKS PARA OUTROS SITES</li>
<p>
Nosso site contém links para websites operados por terceiros que não
o imoveisdeluxo.com.br. O imoveisdeluxo.com.br não tem controle
sobre esses websites, e não se responsabiliza pelo conteúdo dos
mesmos. A disponibilização desses links pelo imoveisdeluxo.com.br
não implica na aprovação do material ou qualquer associação com seus
operadores. Ao acessar e usar esses sites o usuário concorda que o estará fazendo
sob sua própria responsabilidade.
</p>
<p>
A política de privacidade do imoveisdeluxo.com.br é aplicável
somente quando o usuário está utilizando o imoveisdeluxo.com.br;
uma vez linkado a outro website, o usuário estará sujeito à política
de privacidade desse outro website, sobre a qual o
imoveisdeluxo.com.br não tem qualquer relação.
</p>
A imoveisdeluxo.com.br se utiliza de tratamento de dados, conforme preceitua a Lei geral de Proteção de Dados – LGPD (Lei 13.709/2018) em seu art.5°, X; respeitando, dentre outros, o fundamento da privacidade exposto no artigo 2° da LGPD; realizando transferência dos dados fornecidos pelo usuário cadastrado com outras empresas parceiras, com o que o usuário concorda ao assinar nossos termos de uso.
<li>MUDANÇAS no SITE</li>
<p>
O imoveisdeluxo.com.br e seus associados poderão fazer mudanças nas
informações, serviços, produtos e outros materiais do site, ou ainda
encerrar as atividades, a qualquer momento sem nenhuma notificação
prévia ao usuário, no caso de cometimento de ilegalidades ou crimes, na forma do parágrafo único do artigo 20 da lei do Marco Civil da Internet (Lei 12.965/2014).
</p>
<li>ALTERAÇÃO dos TERMOS de USO</li>
<p>
O imoveisdeluxo.com.br poderá modificar, alterar e/ou ajustar estes
termos a qualquer tempo e essas modificações, alterações e/ou
ajustes deverão ser efetivas e imediatas assim que estes se tornem
públicos. Para este efeito, o usuário deverá rever os termos de uso
periodicamente, sendo certo que o acesso ou uso contínuo do portal
pelo usuário subordina-se à aceitação dos termos em vigor.
</p>
<li>LEGISLAÇÃO APLICÁVEL:</li>  
<p>
Este site se subordina às leis vigentes no Brasil, seus princípios e fundamentos, em especial, à Lei do Marco Civil da Internet (Lei 12.965/2014) e Lei geral de Proteção de Dados (Lei 13.709/2018). Estando passível de eventuais alterações para se conformar à legislação em vigor.
Qualquer divergência relacionada ao presente termo de uso deverá ser
submetida às leis do Brasil.
</p>

        </ul>
      </TermsContainer>
    </Container>
  );
};

export default Terms;

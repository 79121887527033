import styled from 'styled-components';

interface AdvertisementProps {
  background_url: string;
}

export const Advertisement = styled.div<AdvertisementProps>`
  display: flex;
  flex: 1;
  background: url(${(props) => props.background_url});
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
  margin-bottom: 20%;
  height: 1200px;
  img {
    width: 100%;
    height: 300px;
  }
`;

export const Adescription = styled.div`
  width: 80%;
  p {
    margin-top: 2%;
    width: 100%;
    background-color: #c1b994;
    height: 60px;
    font-size:16px;
    color:black;
  }
`;


export const Carrosel = styled.div`
  width: 80%;
  place-items: center;
 
`;

export const Adinfo = styled.div`
  display: grid;
  place-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 80%;
  grid-template-columns: repeat(auto-fit, minmax(15px, 1fr));
  p {
    height: 40px;
  }
`;

export const Adseller = styled.div`
  display: grid;
  place-items: center;
  margin-top: 1%;
  margin-bottom: 5%;
  width: 80%;
`;

export const Adlist = styled.div`
  display: grid;
  width: 80%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  list-style: none;
  ul li {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    position: relative;
  }
  h6 {
    color: green;
    margin-bottom: 0px;
  }

  img {
    width: 200px;
    height: 200px;
  }

  li {
    padding: 24px;
    border-radius: 4px;
    position: relative;
    background-color: #c1b994;
  }
`;
